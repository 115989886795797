<!-- 상품옵션 견적요청 내역 -->
<template>
  <div>
    <!--<label>
      <input class="input"
             :id="'file-img'+idx"
             type="file"
             @change="onFileChange" multiple>
    </label>-->
    <!-- 추가된 첨부파일 -->
    <div
      v-for="(file, idx) in items"
      :key="`file-${idx}`"
      class="file-list">
      <div class="file-name">{{ file }}</div>
      <div class="trash" @click="onDelete(idx)">
        <i class="material-icons">delete</i>
      </div>
    </div>
    <!-- 첨부파일 추가하기 -->
    <input class="file-input"
           :id="uid"
           @click.stop
           @change="onFileChange"
           type="file">

    <label :for="uid"
           @click.stop
           class="file-list" v-if="items.length===0">
      <div class="file-attachment-text">+ 첨부파일 추가</div>
      <div class="file-attachment">
        <i class="material-icons">attachment</i>
      </div>
    </label>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props:['files'],
    data () {
      return {
        uid: '',
        // [ '파일', '파일' ]
        items: [],
        uploadReady: true,        // 초기화 용
        file: undefined
      }
    },
    mounted: function() {
      this.uid = moment().format('SSSS')
      //file 타입인 경우
     /* if(typeof this.url === 'object') {
        this.set_img(this.img);
      }
      else {
        this.img = this.image(this.url);
      }*/
    },
    watch: {
      files(n){
        if(n) {
          this.items = n;
        }
      }
    },
    created (){
      if(this.files){
        this.items = this.files;
      }
    },
    methods: {
      onDelete(idx) {
        this.items.splice(idx, 1);
      },
      onFileChange: function(e) {
        var files = e.target.files || e.dataTransfer.files;
        //let list = [];
        if (!files.length)
          return;

        /*for(let i=0; i<files.length; i++){
          var reader = new FileReader();
          reader.onload = function(e) {
            list.push(e.target.result)
            //console.log(e.target.result);
          }.bind(list);
          reader.readAsDataURL(files[i]);


        }*/
        var reader = new FileReader();
        reader.onload = function(e) {
          this.items.push(e.target.result);
          this.$emit('isHide')
        }.bind(this);
        reader.readAsDataURL(files[0]);

        this.$emit('update:files', this.items);
      },
      isImage(ext) {
        let isImg = false;
        let imgExt = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff', 'tif'];
        ext = ext.toLowerCase();
        if(imgExt.indexOf(ext)>-1)
          isImg = true;

        return isImg;
      },
      extColor(ext) {
        let deco = { backgroundColor : '' };
        switch(ext.toLowerCase()) {
          case 'hwp':
            deco.backgroundColor = '#2ebcee';
            break;
          case 'pdf':
            deco.backgroundColor = '#ef4037';
            break;
          case 'docx':
            deco.backgroundColor = '#295498';
            break;
          case 'pptx':
            deco.backgroundColor = '#d14425';
            break;
          case 'xlsx':
            deco.backgroundColor = '#207245';
            break;
        }

        return deco;
      },
      upper(ext) {
        return ext.toUpperCase();
      },
    }
  }
</script>

<style scoped>
  .file-list {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 35px;
    cursor: pointer;
  }

  .thumb {
    width: 35px;
    height: 35px;
  }

  .file-attachment {
    width: 35px;
    height: 33px;
  }

  .ext {
    text-align: center;
    color: white;
    background-color: #064160;
    line-height: 35px;
    font-size: 0.7rem;
    font-weight: 100;
  }

  .file-attachment-text {
    padding-left: 12px;
    line-height: 35px;
    font-size: 0.9rem;
    color: rgba(162, 174, 186, 1);
  }

  .file-attachment {
    background-color: rgba(255, 219, 70, 1);
    text-align: center;
  }

  .file-attachment i {
    line-height: 35px;
    font-size: 1.6rem;
  }

  .file-desc {
    font-size: 0.7rem;
    color: rgba(162, 174, 186, 1);
    white-space: pre-line;
    padding: 8px 0;
  }

  .file-name {
    padding: 0 20px;
    color: #303841;
    font-size: 0.8rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    height: 100%;
    line-height: 35px;
    text-align: left;
    flex: 1;
    max-width: unset;
  }

  .file-input {
    width: 0;
    height: 0;
    position: fixed;
    top: -100px;
    left: -100px;
  }

  .trash {
    width: 35px;
    height: 35px;
    text-align: center;
  }

  .trash i {
    line-height: 35px;
    color: rgba(162, 174, 186, 1);
    font-size: 1.4rem;
    cursor: pointer;
  }
</style>
